.App {
  height: 100vh;
  width: 100%;
}

.background {
  margin: 0;
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 100vh;
  width: 40%;
  font-size: 32px;
  color: white;
  font-family: "GroteskDS Pro", sans-serif;
}

.header {
  /* font-weight: bold; */
}

.examples,
.welcome-note {
  text-align: left;
  width: 100%;
}

.arrow-icon {
  height: auto;
  width: 60px;
  margin-right: 8px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.copyright {
  font-size: 24px;
}

.social-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social-icon img {
  height: 48px;
  width: 48px;
  margin: 8px;
}

/* For tablet devices */
@media (max-width: 768px) {
  .content-container {
    width: 60%;
    font-size: 24px;
  }

  .copyright {
    font-size: 20px;
  }

  .social-icon img {
    height: 36px;
    width: 36px;
    margin: 6px;
  }

  .arrow-icon {
    width: 28px;
    margin-right: 6px;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .content-container {
    width: 80%;
    font-size: 18px;
  }

  .copyright {
    font-size: 16px;
  }

  .social-icon img {
    height: 24px;
    width: 24px;
    margin: 4px;
  }

  .arrow-icon {
    width: 36px;
    margin-right: 4px;
  }

  .footer {
    flex-wrap: wrap-reverse;
    text-align: center;
    justify-content: center;
  }

  .arrow-icon {
    height: auto;
    width: 18px;
    margin-right: 8px;
  }

  .contact-email {
    font-size: 18px;
  }
}

.contact {
  display: flex;
}
